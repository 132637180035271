var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Browse',{attrs:{"columns":[
    'date_time',
    'description',
    'sales_person',
    'task_type',
    'task_status',
    'customer',
    'contact' ],"formats":{
    date_time: 'DateTime',
  },"itemsPerPage":"10","searchOnStart":true,"table":"tasks"}})}
var staticRenderFns = []

export { render, staticRenderFns }